import React from 'react'

const Contact = () => {
  return (
<>
 <div style={{ height: '100vh', width: '100%',  }} className='hide_scrollbar'>
      <iframe
      className='hide_scrollbar'
        src="https://mamaket-web.vercel.app/contact"
        title="Embedded Website"
        style={{ height: '100%', width: '100%', border: 'none' }}
      />
    </div>
</>
  )
}

export default Contact