import React from "react";
import {useRoutes} from "react-router-dom";
import Waitlist from './Waitlist'
import Privacy from './Waitlist/Privacy'
import Contact from './Waitlist/Contact'

export function Routes(){
    return useRoutes([
        {
            path: '/', element: <Waitlist/>
        },
              {
            path:'/privacy-policy',
            element:<Privacy/>
        },
        {
            path:'/contact',
            element:<Contact/>
        }
    ])
}
