import React, {useState, useEffect} from 'react';
import { Button, Box,  InputLabel, TextField, Typography,  } from '@mui/material'
import Modal from '@mui/material/Modal';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Loader from './Loader';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CustomForm = ({ status, message, onValidated, user }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [success, setSuccess] = useState(false)

    console.log(user)
const userType = user
    useEffect(() => {
        if(status === "success") clearFields();
      }, [status])
    
      const clearFields = () => {
        
        setSuccess(true)
        setFirstName('');
        setLastName('');
        setEmail('');
      }

    const handleSubmit = (e) => {
        e.preventDefault();

        firstName &&
        lastName &&
        email &&
        email.indexOf("@") > -1 &&   
        onValidated(
       
          {
 
            MERGE1: firstName,
            MERGE2: lastName,
            EMAIL: email,
            MERGE6:userType
        });
 
    }
    console.log(status,message, "=>Sammy")

    return (
<>
{
  success ?
  <Typography variant='h3' sx={{fontSize:'30px'}}>{message}</Typography>
  :
<>
<Box>
    {success && <p>{message}</p> }
    {status === "sending" && <Loader/>}
         <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    // fontFamily: "Gordita",
                  }}
                >
                  First Name
                </InputLabel>
                <TextField
                  placeholder="Enter your first name"
                  size="large"
                  fullWidth
                  value={firstName}
                  onChange={(e)=>setFirstName(e.target.value)}
                  InputProps={{
                    style: {
                    //   fontFamily: "Gordita",
                    marginTop:'10px',
                    marginBottom:'20px',
                      fontSize: "13px",
                      borderRadius: "20px",
                      offset: " 1px solid #737373",
                      // Replace with your desired font family
                    },
                  }}
                />
                </Box>
            <Box>
         <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    // fontFamily: "Gordita",
                  }}
                >
                 Last Name
                </InputLabel>
                <TextField
                  placeholder="Enter your last name"
                  size="large"
                  fullWidth
                  value={lastName}
                  onChange={(e)=>setLastName(e.target.value)}
                  InputProps={{
                    style: {
                    //   fontFamily: "Gordita",
                    marginTop:'10px',
                    marginBottom:'20px',
                      fontSize: "13px",
                      borderRadius: "20px",
                      offset: " 1px solid #737373",
                      // Replace with your desired font family
                    },
                  }}
                />
                </Box>
            <Box>
         <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    // fontFamily: "Gordita",
                  }}
                >
                Email
                </InputLabel>
                <TextField
     
                  placeholder="Enter your email"
                  size="large"
                  fullWidth
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  InputProps={{
                    style: {
                    //   fontFamily: "Gordita",
                    marginTop:'10px',
                    marginBottom:'20px',
                      fontSize: "13px",
                      borderRadius: "20px",
                      offset: " 1px solid #737373",
                      // Replace with your desired font family
                    },
                  }}
                />
                </Box>
        
                <Box align="center">

                <Button onClick={handleSubmit } variant='contained' sx={{borderRadius:'20px', mt:5, }} fullWidth     formValues={[email, firstName, lastName]} >Join the waitlist</Button></Box>

                </>
}
</>
    );
};

export default CustomForm