import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import cart from "../../../assets/cart.png";
import Aos from "aos";
import "aos/dist/aos.css";
import ChangedText from "../ChangedText";

const WhatWeProvide = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Box align="center" sx={{}}>
        <Typography
          variant="body1"
          sx={{
            mt: { lg: 13, md: 13, sm: 10, xs: 8 },
            color: "#000",
            width: { lg: "50%", md: "50%", sm: "70%", xs: "80%" },
            textAlign: "justify",
          }}
        >
          You are being invited to join Mamaket beta version. Mamaket is a
          flagship of Mamaket, Inc, an e-commerce industry established in 2023
          for immigrant communities in the United States.We provide visibility
          for products and services of immigrant cultures.​ Most immigrants
          struggle to locate goods and services of their own cultures. ​ We want
          to be known as the biggest platform for buying and selling products
          from different ethnic groups not just in United States but also in
          countries that have large immigrant population. ​ Immigrants suffer
          from homesickness also. We want to cure that. ​ Ultimately, Mamaket is
          a hub for different cultural things: goods, services, entertainment,
          travel and events. Yes, events - cultural events, festivals, carnival,
          we got you. ​ We want to make you feel at home in any foreign land.
        </Typography>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: { lg: "85%", md: "85%", sm: "90%", xs: "90%" },
          mt: { lg: 10, md: 10, sm: 8, xs: 5 },
        }}
      >
        {isMobile ? (
          <Typography
            variant="h3"
            sx={{
              fontSize: { lg: "50px", md: "50px", sm: "30px", xs: "30px" },
            }}
          >
            Why you should start with us!
          </Typography>
        ) : (
          <Typography
            variant="h3"
            sx={{
              fontSize: { lg: "50px", md: "50px", sm: "30px", xs: "30px" },
            }}
          >
            Why you should start
            <br />
            with us!
          </Typography>
        )}

        <Grid
          container
          sx={{ mt: { lg: 10, md: 10, sm: 8, xs: 0 }, mb: 5 }}
          spacing={3}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box data-aos="fade-up">
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#333",
                  fontSize: { lg: "24px", md: "24px", sm: "20px", xs: "18px" },
                }}
              >
                Diverse selection of{" "}
                <span
                  style={{
                    // background: "#CC9C00",
                    padding: "2px",
                    marginRight: "2px",
                    // color: "#fff",
                  }}
                >
                  cultural
                </span>
                products and services
              </Typography>
              <Typography variant="body1">
                We offer a wide range of exotic
                products and services from trusted brands and vendors in the US.
                As a seller you can upload  your  cultural products and services in 4 easy steps.
              </Typography>
            </Box>
            <Box data-aos="fade-up" sx={{ mt: 8, ml: { lg: 10, md: 2 } }}>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#333",
                  fontSize: { lg: "24px", md: "24px", sm: "20px", xs: "18px" },
                }}
              >
                Hassle-free shopping experience
              </Typography>
              <Typography variant="body1">
                Shopping in our platform is easy and user-friendly. Using our
                chatting system, you can communicate with sellers and negotiate
                prices in a safe and secure environment.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={5.8}
            sm={12}
            xs={12}
            sx={{ "& > img": { ml: { lg: -20, md: -20, sm: -10, xs: -11 } } }}
          >
            <img src={cart} width="130%" data-aos="zoom-in" />
          </Grid>
          <Grid item lg={3} md={3.2} sm={12} xs={12}>
            <Box
              data-aos="fade-right"
              sx={{
                ml: { lg: -12, md: -12, xs: 0 },
                mt: 8,
                pr: { lg: 10, md: 10, sm: 0, xs: 0 },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#333",
                  fontSize: { lg: "24px", md: "24px", sm: "20px", xs: "18px" },
                }}
              >
                Secured payment platform
              </Typography>
              <Typography variant="body1">
                With Mamapurse, our e-wallet system, both buyers and sellers
                don’t need credit or debit cards to have a safe and secure
                transaction process and a transparent return and exchange
                policy, so you can shop with confidence.
              </Typography>
            </Box>
            <Box
              data-aos="fade-right"
              sx={{ ml: { lg: -7, md: -7, sm: 0, xs: 0 }, mt: 5, pr: 2 }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#333",
                  fontSize: { lg: "24px", md: "24px", sm: "20px", xs: "18px" },
                }}
              >
                Get your{" "}
                <span
                  style={{
                    // background: "#CC9C00",
                    padding: "2px",
                    marginRight: "2px",
                    // color: "#fff",
                  }}
                >
                  culture-based
                </span>
                brands to a wider audience in the US
              </Typography>
              <Typography variant="body1">
                We promote product and service visibility locally and also
                throughout the US.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WhatWeProvide;
