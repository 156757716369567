import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import Banner from "./Component/Header/Banner";
import Footer from "./Component/Footer";
import WhatWeProvide from "./Component/Body/WhatWeProvide";
import Culture from "./Component/Body/Culture";
import Faqs from "./Component/Body/Faqs";
import Products from "./Component/Body/Products";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2E1834",
    },
  },
  typography: {
    h1: {
      fontFamily: "syne",
      fontWeight: 700,
      fontSize: "110px",
      color: "#fff",
      lineHeight: { lg: "98px", xs: "50px" },
    },
    h2: {
      fontFamily: "inter",
      fontWeight: 300,
      color: "#fff",
      fontSize: "20px",
      lineHeight: "30px",
    },
    button: {
      fontFamily: "syne",
      textTransform: "initial",
    },
    caption: {
      fontFamily: "syne",
      fontWeight: 200,
    },
    subtitle1: {
      fontFamily: "poppins",
      color: "#000",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "34px",
    },
    h3: {
      fontFamily: "syne",
      fontWeight: 700,
      color: "#333333",
      fontSize: "50px",
    },
    body1: {
      fontFamily: "syne",
      fontWeight: 300,
      fontSize: "15px",
      color: "#00000080",
      lineHeight: "28px",
      letterSpacing: "0.01em",
    },
  },
});

const index = () => {
  return (
    <ThemeProvider theme={theme}>
      <Banner />
      <WhatWeProvide />
      <Culture />
      <Products />
      <Faqs />
      <Footer />
    </ThemeProvider>
  );
};

export default index;
