import {
  Box,
  IconButton,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import applestore from "../../../assets/appstore.svg";
import playstore from "../../../assets/googlestore.svg";
import Signup from "../Signup";
import mockup from "../../../assets/mockup.svg";

const Faqs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Box sx={{ my: 10, mb: 17 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "50%", md: "50%", sm: "85%", xs: "90%" },
            boxShadow:
              "0px 289px 115px rgba(0, 0, 0, 0.01), 0px 162px 97px rgba(0, 0, 0, 0.04), 0px 72px 72px rgba(0, 0, 0, 0.06), 0px 18px 40px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07)",
            p: { lg: 2, md: 2, sm: 1.5, xs: 1 },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { lg: "50px", md: "50px", sm: "30px", xs: "30px" },
            }}
          >
            FAQs
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Accordion
              expanded={expanded === "panel1"}
              sx={{ border: "1px solid #2E1834" }}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0, color: "#000" }}>
                  How does it work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  <ul>
                    <li>
                      {" "}
                      Download the Mamaket mobile app or use the website to
                      browse deals on tons of great items nearby.
                    </li>
                    <li>
                      Instantly message the seller securely through the app to
                      negotiate prices and set up delivery or meetup options.
                    </li>
                    <li>
                      Make and receive payment using the Mamaket e-wallet system
                      known as Mamapurse.
                    </li>
                    <li>
                      Sell something of your own, by simply taking a picture
                      with your phone, you can sell old and new products as well
                      as services. Posting takes less than 30 seconds!
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ border: "1px solid #2E1834", mt: 2 }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0, color: "#000" }}>
                  What is a marketplace?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  A marketplace is a platform or an environment where buyers and
                  sellers come together to engage in commercial transactions.
                  The marketplace provides a venue for sellers to offer their
                  goods or services to potential customers, who can compare
                  prices and quality, make purchases, and provide feedback.
                  Marketplaces can be physical locations, such as farmers
                  markets, or online platforms like Mamaket. In recent years,
                  there has been a significant increase in the number of online
                  marketplaces, providing buyers and sellers with greater access
                  to a wider range of products and services, and enabling them
                  to transact business more efficiently and conveniently.
                  Basically, what you need is here just type the search.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>

      {isMobile ? (
        <>
          <Box sx={{ bgcolor: "#f9f9f9" }}>
            <Box sx={{ margin: "0 auto", width: "90%", py: 6 }}>
              <Grid container>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "end",
                    overflow: "hidden",
                  }}
                >
                  <Box>
                    {" "}
                    <img src={mockup} width="99.1%" />
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "30px",
                        xs: "25px",
                      },
                      color: "#303030",
                      lineHeight: {
                        lg: "50px",
                        md: "50px",
                        sm: "40px",
                        xs: "30px",
                      },
                      fontFamily: "syne",
                      textAlign: "center",
                      mb: 1.2,
                    }}
                  >
                    Coming soon on App Store and Google Play Store
                  </Typography>
                  <Typography
                    variant="body1"
                    // sx={{ fontSize: "15px", color: "#757575" }}
                  >
                    For us, helping our customer to fulfill their needs is
                    number one so that’s why we are available on every platform.
                  </Typography>
                  <Box mt={2}>
                    <IconButton sx={{ borderRadius: "10px", ml: -2 }}>
                      <img src={applestore} alt="applestore" />
                    </IconButton>
                    <IconButton sx={{ borderRadius: "10px" }}>
                      <img src={playstore} alt="playstore" />
                    </IconButton>
                    <Box sx={{ mt: 2, ml: -1 }}>
                      <Signup color="primary" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ bgcolor: "#f9f9f9" }}>
          <Box sx={{ margin: "0 auto", width: "85%", py: 6 }}>
            <Grid container>
              <Grid item lg={4} md={4} xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "30px",
                      xs: "30px",
                    },
                    fontFamily: "syne",
                    color: "#303030",
                    lineHeight: {
                      lg: "50px",
                      md: "50px",
                      sm: "40px",
                      xs: "40px",
                    },
                    mb: 1.2,
                  }}
                >
                  Coming soon on App Store and Google Play Store
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "15px", color: "#757575" }}
                >
                  For us, helping our customer to fulfill their needs is number
                  one so that’s why we are available on every platform.
                </Typography>
                <Box mt={2}>
                  <IconButton sx={{ borderRadius: "10px", ml: -2 }}>
                    <img src={applestore} alt="applestore" />
                  </IconButton>
                  <IconButton sx={{ borderRadius: "10px" }}>
                    <img src={playstore} alt="playstore" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item lg={4} align="center" xs={12}>
                <Box sx={{ pt: 10 }}>
                  <Signup color="primary" />
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                sx={{ height: "400px", display: "flex", justifyContent: "end" }}
              >
                <Box align="right">
                  {" "}
                  <img
                    src={mockup}
                    width="99.1%"
                    style={{ marginTop: "-100px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Faqs;
