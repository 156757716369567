import { Box, Button, Grid, Typography, useTheme, Avatar, useMediaQuery } from "@mui/material";
import React from "react";
import "../../../STYLES/main.css";
import logo from "../../../assets/Logo/logo.svg";
import img1 from "../../../assets/2.webp";
import img2 from "../../../assets/1.webp";
import img3 from "../../../assets/3.webp";
import img4 from "../../../assets/4.webp";
import img5 from "../../../assets/5.webp";
import img6 from "../../../assets/6.webp";
import img7 from "../../../assets/7.webp";
import img8 from "../../../assets/8.webp";
import Signup from "../Signup";
import ChangedText from "../ChangedText";

const Banner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Box sx={{ bgcolor: "#0F0811", pb: 3 }}>
        <Box className="banner_pattern" sx={{ height: "100%" }}>
          <Box className="banner_map">
            <Box className="banner_backdrop" sx={{ height: "100%" }}>
              <Box  sx={{ pt: 5, margin:'0 auto', width:'70%' }}>
                <img src={logo} alt="Mamaket_Logo" width={70} />
              </Box>
              <Box
                align="center"
                sx={{ mt: { lg: 10, md: 14, sm: 12, xs: 8 }, px: 2 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      lg: "100px",
                      md: "70px",
                      sm: "40px",
                      xs: "40px",
                    },
                    mb: 2,
                  }}
                >
                  Looking to sell cultural <br /> things in the USA?
                </Typography>

                <Signup />

                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      lg: "18px",
                      md: "18px",
                      sm: "15px",
                      xs: "15px",
                    },
                    mt: 3,
                    color: "#fff",
                  }}
                >
                  A marketplace for  products and services of immigrant cultures
                </Typography>
              </Box>

              <Box
                sx={{
                  bgcolor: theme.palette.primary.main,
                  margin: "0 auto",
                  width: { lg: "80%", md: "80%", sm: "95%", xs: "95%" },
                  height: { lg: "400px", md: "400px", sm: "auto", xs: "auto" },
                  borderRadius: {
                    lg: "20px",
                    md: "20px",
                    sm: "10px",
                    xs: "10px",
                  },
                  mt: 4,
                  pt: { lg: 15, md: 15, sm: 10, xs: 4 },
                  pb: 2,
                }}
              >
                <Box
                  className="banner_background_hero"
                  sx={{
                    margin: "0 auto",
                    width: "90%",
                    height: {
                      lg: "500px",
                      md: "500px",
                      sm: "auto",
                      xs: "auto",
                    },
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      height: "100%",
                      margin: "0 auto",
                      width: "90%",
                      position: "relative",
                      top: { lg: -100, md: -100, xs: -30 },
                    }}
                    spacing={2}
                  >
                    <Grid item lg={3} md={3} xs={6}>
                      <Box
                        sx={{
                          backgroundImage: `url('${img1}')`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          border: {
                            lg: "4px solid #FFCD29",
                            md: "4px solid #FFCD29",
                            sm: "4px solid #FFCD29",
                            xs: "2px solid #FFCD29",
                          },
                          height: {
                            lg: "50%",
                            md: "50%",
                            sm: "150px",
                            xs: "150px",
                          },
                          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          width: "100%",
                          borderRadius: "0px",
                          backgroundColor: "#fff",
                        }}
                      />
                      {!isMobile && (
                        <Box
                          sx={{
                            backgroundImage: `url('${img5}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            border: {
                              lg: "4px solid #FFCD29",
                              md: "4px solid #FFCD29",
                              sm: "4px solid #FFCD29",
                              xs: "2px solid #FFCD29",
                            },
                            mt: 1,
                            height: "50%",
                            boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            width: "100%",
                            borderRadius: "0px",
                            backgroundColor: "#fff",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <Box
                        sx={{
                          backgroundImage: `url('${img2}')`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          border: {
                            lg: "4px solid #FFCD29",
                            md: "4px solid #FFCD29",
                            sm: "4px solid #FFCD29",
                            xs: "2px solid #FFCD29",
                          },
                          height: {
                            lg: "50%",
                            md: "50%",
                            sm: "150px",
                            xs: "150px",
                          },
                          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          width: "100%",
                          borderRadius: "0px",
                          backgroundColor: "#fff",
                        }}
                      />
                      {!isMobile && (
                        <Box
                          sx={{
                            backgroundImage: `url('${img6}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            border: {
                              lg: "4px solid #FFCD29",
                              md: "4px solid #FFCD29",
                              sm: "4px solid #FFCD29",
                              xs: "2px solid #FFCD29",
                            },
                            mt: 1,
                            height: "50%",
                            boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            width: "100%",
                            borderRadius: "0px",
                            backgroundColor: "#fff",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <Box
                        sx={{
                          backgroundImage: `url('${img3}')`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          border: {
                            lg: "4px solid #FFCD29",
                            md: "4px solid #FFCD29",
                            sm: "4px solid #FFCD29",
                            xs: "2px solid #FFCD29",
                          },
                          height: {
                            lg: "50%",
                            md: "50%",
                            sm: "150px",
                            xs: "150px",
                          },
                          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          width: "100%",
                          borderRadius: "0px",
                          backgroundColor: "#fff",
                        }}
                      />
                      {!isMobile && (
                        <Box
                          sx={{
                            backgroundImage: `url('${img7}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            border: {
                              lg: "4px solid #FFCD29",
                              md: "4px solid #FFCD29",
                              sm: "4px solid #FFCD29",
                              xs: "2px solid #FFCD29",
                            },
                            mt: 1,
                            height: "50%",
                            boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            width: "100%",
                            borderRadius: "0px",
                            backgroundColor: "#fff",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <Box
                        sx={{
                          backgroundImage: `url('${img4}')`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          border: {
                            lg: "4px solid #FFCD29",
                            md: "4px solid #FFCD29",
                            sm: "4px solid #FFCD29",
                            xs: "2px solid #FFCD29",
                          },
                          height: {
                            lg: "50%",
                            md: "50%",
                            sm: "150px",
                            xs: "150px",
                          },
                          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          width: "100%",
                          borderRadius: "0px",
                          backgroundColor: "#fff",
                        }}
                      />

                      {!isMobile && (
                        <Box
                          sx={{
                            backgroundImage: `url('${img8}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            border: {
                              lg: "4px solid #FFCD29",
                              md: "4px solid #FFCD29",
                              sm: "4px solid #FFCD29",
                              xs: "2px solid #FFCD29",
                            },
                            mt: 1,
                            height: "50%",
                            boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            width: "100%",
                            borderRadius: "0px",
                            backgroundColor: "#fff",
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Banner;
