import { Box, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import logo from "../../../assets/Logo/logoWhite.svg";
import facebook from "../../../assets/Icons/facebook.svg";
import instagram from "../../../assets/Icons/instagram.svg";
import twitter from "../../../assets/Icons/twitter.svg";
import linkedin from "../../../assets/Icons/linkedin.svg";

const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      {isMobile ? (
        <Box sx={{ height: "188px", bgcolor: "#101010" }}>
          <Box
            sx={{
              margin: "0 auto",
              width: "85%",
              display: "flex",
              alignItems: "center",
              height: "188px",
            }}
          >
            <Grid container rowSpacing={2}>
              <Grid item sm={12} xs={12} align="center">
                <img src={logo} alt="Mamaket_Logo" width="30%" />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: "14px", color: "#fff" }}
                >
                  Powered by Mamaket, Inc
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Stack direction="row" spacing={2}>
                  <a href="https://www.facebook.com/mamaket9ja?mibextid=LQQJ4d">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "35px",
                        height: "35px",
                      }}
                    >
                      <img src={facebook} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>
                  <IconButton
                    sx={{
                      background: "#5A71841a",
                      width: "35px",
                      height: "35px",
                    }}
                  >
                    <img src={twitter} alt="mamaket_social_icon" />
                  </IconButton>
                  <a href="https://instagram.com/mamaketinc?igshid=MjEwN2IyYWYwYw==">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "35px",
                        height: "35px",
                      }}
                    >
                      <img src={instagram} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>
                  <a href="https://www.linkedin.com/in/mamaket-inc-a14196216">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "35px",
                        height: "35px",
                      }}
                    >
                      <img src={linkedin} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "168px", bgcolor: "#101010" }}>
          <Box
            sx={{
              margin: "0 auto",
              width: "85%",
              display: "flex",
              alignItems: "center",
              height: "168px",
            }}
          >
            <Grid container>
              <Grid item lg={4} md={4}>
                <img src={logo} alt="Mamaket_Logo" width="40%" />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: "14px", color: "#fff" }}
                >
                  Powered by Mamaket, Inc
                </Typography>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <Stack direction="row" spacing={2}>
                  <a href="https://www.facebook.com/mamaket9ja?mibextid=LQQJ4d">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "42px",
                        height: "42px",
                      }}
                    >
                      <img src={facebook} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>

                  <a href="https://twitter.com/MamaketN">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "42px",
                        height: "42px",
                      }}
                    >
                      <img src={twitter} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>

                  <a href="https://www.instagram.com/mamaketinc/">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "42px",
                        height: "42px",
                      }}
                    >
                      <img src={instagram} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>

                  <a href="https://www.linkedin.com/company/99913366/admin/feed/posts/">
                    <IconButton
                      sx={{
                        background: "#5A71841a",
                        width: "42px",
                        height: "42px",
                      }}
                    >
                      <img src={linkedin} alt="mamaket_social_icon" />
                    </IconButton>
                  </a>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Footer;
